import React from "react";
import Sidebar from "./Sidebar";

const Documents = () => {

    return (
        <>
            <Sidebar />

            <div className="home-main-content">

                <div className="container-fluid pt-4">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="upload-column">
                                <div>
                                    <h5>Submitted Documents</h5>
                                    <p>Below are your submitted documents</p>
                                </div>

                                <div className="row pt-4">
                                    <div className="col-lg-8">
                                        <div className="submit-box">
                                            <h4>1.Aadhar Card</h4>
                                            <div className="submit-doc">
                                                <img src="images/submit.png" alt="submitted-img" />
                                                <img src="images/submit.png" alt="submitted-img" />
                                            </div>
                                            <h5>Uploaded Date :- <span>15-04-2024</span> </h5>
                                            <h6 className="verified"><i class="bi bi-check-circle-fill"></i> Verified</h6>
                                        </div>

                                    </div>
                                    <div className="col-lg-8 pt-4">
                                        <div className="submit-box">
                                            <h4>2. Incorp Certificate</h4>
                                            <div className="submit-doc">
                                                <img src="images/incorp.png" alt="submitted-img" />
                                            </div>
                                            <h5>Uploaded Date :- <span>15-04-2024</span> </h5>
                                            <h6 className="pending"><i class="bi bi-check-circle-fill"></i> Pending</h6>
                                        </div>

                                    </div>
                                </div>
                                <div className="detail-btn pt-4">
                                    <button>Confirm & Next</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default Documents;