import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Login from "./Components/Login";
import Register from "./Components/Register";
import Forget from "./Components/Forget";
import Dashboard from "./Components/Dashboard";
import Kycprocess from "./Components/Kycprocess";
import Uploaddoc from "./Components/Uploaddoc";
import Uploaddetails from "./Components/Uploaddetails";
import Documents from "./Components/Documents";
import Profile from "./Components/Profile";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login/>}/>
          <Route exact path="/register" element={<Register/>}/>
          <Route exact path="/forget_password" element={<Forget/>}/>
          <Route exact path="/dashboard" element={<Dashboard/>}/>
          <Route exact path="/kyc" element={<Kycprocess/>}/>
          <Route exact path="/upload" element={<Uploaddoc/>}/>
          <Route exact path="/detail" element={<Uploaddetails/>}/>
          <Route exact path="/documents" element={<Documents/>}/>
          <Route exact path="/profile" element={<Profile/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
